


































import { Vue, Component, Prop } from 'vue-property-decorator';
import cPlashka, { IPlashkaData } from '@/modules/plashki/components/cPlashka.vue';
export interface ITabPlashkaData {
    indx: number;
    name: string;
    plashkaData: IPlashkaData[];
}

@Component({
    components: {
        'c-plashka': cPlashka
    }
})
export default class CPlashTab extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private tabData!: IPlashkaData[];
    @Prop({
        required: false,
        default: false
    })
    private flEditTab!: boolean;
    @Prop({
        required: true,
        default: 3
    })
    private colSum!: number | string;
    @Prop({
        required: true,
        default: false
    })
    private isTablet!: boolean;
    private plashkaArrayToPage: IPlashkaData[] = [];
    // private perPage = 6; // количество плашек на странице
    public pgRows = 1;
    public curPage = 1;

    private get getColSum(): number {
        let colSum: number | string = this.colSum;
        if (typeof (this.colSum) === 'string') {
            colSum = Number(this.colSum);
        }
        return colSum as number;
    }

    private get perPage(): number { // количество плашек на странице
        if (!this.isTablet) {
            return (this.getColSum as number) * 3;
        }
        return this.tabData.length;
    }

    private get plashkaArray(): IPlashkaData[] {
        this.pgRows = this.tabData.length;
        this.setPlashToPage(this.cloneArr(this.tabData.slice()));
        return this.tabData.slice();
    }

    private set plashkaArray(val: IPlashkaData[]) {
        this.$emit('changePl', val);
    }

    // заполнить массив plashkaArrayToPage
    private setPlashToPage(plash: IPlashkaData[]) {
        let begIndx = (this.curPage - 1) * this.perPage;
        if (begIndx >= plash.length) { begIndx = plash.length - 1; }
        const endIndx = begIndx + this.perPage;
        if (endIndx >= plash.length) {
            this.plashkaArrayToPage = plash.slice(begIndx);
        } else {
            this.plashkaArrayToPage = plash.slice(begIndx, endIndx);
        }
    }
    // обновление массива
    private plashUpdate(val: IPlashkaData[]) {
        const begIndx = (this.curPage - 1) * this.perPage;
        const endIndx = begIndx + this.perPage;
        let newArr: IPlashkaData[] = [];
        newArr = newArr.concat(this.plashkaArray.slice(0, begIndx));
        newArr = newArr.concat(val);
        newArr = newArr.concat(this.plashkaArray.slice(endIndx));
        this.plashkaArray = newArr;
        this.setPlashToPage(this.plashkaArray);
    }

    private cloneArr(arr: any[]): any[] {
        const newArr: any[] = [];
        for (const el of arr) {
            newArr.push(Object.assign({}, el));
        }

        return newArr;
    }
}
