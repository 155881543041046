
















































import { Vue, Component, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';

import { TWidget } from '@/modules/widget-yard/store/types';
import CWidget from '@/modules/widget-yard/c-widget';

export interface IPlashkaData {
    checked: boolean | null; // выбрана ли плашка для добавления
    widget: TWidget;
}

@Component({
    components: {
        'draggable': draggable,
        'c-widget': CWidget
    }
})
export default class CPlashka extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private data!: IPlashkaData[];

    @Prop({
        required: false,
        default: false
    })
    private flEditTab!: boolean;

    @Prop({
        required: true,
        default: 3
    })
    private colSum!: number;

    private get plashkaData(): IPlashkaData[] {
        return this.data.slice();
    }

    private set plashkaData(val: IPlashkaData[]) {
        this.$emit('plashUpdate', val);
    }

    private showModalPlash = false;

    private modalData: IPlashkaData | null = null;

    // eslint-disable-next-line no-shadow
    private dblClkModalPlash(event: any, element: IPlashkaData) {
        if ((event.target as HTMLElement).className === 'c-card--header') {
            return;
        }
        this.modalData = element;

        this.showModalPlash = true;
    }
}
