























































































































import { Vue, Component } from 'vue-property-decorator';
import CPlashTab, { ITabPlashkaData } from '@/modules/plashki/components/cTabPlashka.vue';
import { IPlashkaData } from '@/modules/plashki/components/cPlashka.vue';
import CWidgetDate from '@/modules/widget-yard/c-widget-date';
import { accessor } from '@/modules/widget-yard/store';
import { TWidget } from '@/modules/widget-yard/store/types';


@Component({
    components: {
        'c-plashTab': CPlashTab,
        'c-widget-date': CWidgetDate
    }
})
export default class Plashki extends Vue {
    private tabIndex = 0; // текущая активная вкладка (индекс)
    private tabs: number[] = []; // массив вкладок
    private tabPlashkaData: ITabPlashkaData[] = []; // данные плашек со всех табов
    private colSum = 3; // количество колонок
    private flNewTab = false; // создание новой вкладки
    private newTabName = ''; // имя новой вкладки
    // ---------- редактирование ----------------
    private flEditTab = false; // редактирование закладок
    private tabEditDataAll: IPlashkaData[] = []; // массив всех возможных плашек для режима редактирования
    private tabEditData: IPlashkaData[] = [];
    private flEditShowAll = 'yes';
    private readonly maxTabWidth = 1200; // максимальная ширина для планшета
    private showEditPanel = false; // показать панель редактирования (на кнопку Редактирование)

    private isMobile = false; // режим мобильного телефона <500px

    // данные плашек со всех табов
    private get getTabPlashkaData() {
        if (this.tabPlashkaData.length === 0 && this.getPlashkaData() !== null && this.getPlashkaData().length > 0) {
            this.tabPlashkaData = [];
            this.tabPlashkaData.push({ indx: 0, name: 'Все', plashkaData: this.getPlashkaData() });
            this.getPlashki();
        }
        return this.tabPlashkaData;
    }


    private getPlashkaData(): IPlashkaData[] {
        const allWidgets: TWidget[] = accessor.widgets;
        const data: IPlashkaData[] = [];
        for (const widg of allWidgets) {
            data.push({ checked: null, widget: widg });
        }
        return data;
    }

    // добавить новую вкладку
    private newTab() {
        this.newTabName = '';
        this.flNewTab = true;
        this.$nextTick(() => {
            const el = this.$refs.plNewNameTab as HTMLElement;
            el.focus();
        });
    }

    // введено новое имя вкладки
    private tabEnter(newTab: boolean): boolean {
        if (this.newTabName.length === 0) {
            // eslint-disable-next-line no-alert
            alert('Пустое имя!');
            return false;
        }
        for (let i = 0; i < this.tabPlashkaData.length; i++) {
            const tab = this.tabPlashkaData[i];
            if (tab.name === this.newTabName) {
                if (!(!newTab && this.tabIndex === i)) {
                    // eslint-disable-next-line no-alert
                    alert('Повтор в имени!');
                    return false;
                }
            }
        }
        if (newTab) {
            this.tabPlashkaData.push({ indx: this.tabPlashkaData.length, name: this.newTabName, plashkaData: [] });
            this.flNewTab = false;
            this.$nextTick(() => {
                this.tabIndex = this.tabPlashkaData.length - 1;
            });
        } else {
            this.tabPlashkaData[this.tabIndex].name = this.newTabName;
        }
        return true;
    }

    private chgTabData(val: IPlashkaData[]) {
        if (!this.flEditTab) {
            this.tabPlashkaData[this.tabIndex].plashkaData = val;
            this.savePlashki();
        } else {
            this.tabEditData = val;
            if (this.flEditShowAll === 'no') { this.removeUncheckPlash(); }
        }
    }
    private lostFocus() {
        const el = this.$refs.plNewNameTab as HTMLElement;
        if (el === undefined) { return; }
        el.focus();
    }
    private abortAddTab() { // отмена добавления
        this.flNewTab = false;
    }
    private delTab() { // удалить вкладку
        this.tabPlashkaData.splice(this.tabIndex, 1);
        this.savePlashki();
    }
    private editTab() { // начать редактирование вкладки
        if (this.tabPlashkaData === undefined || this.tabPlashkaData.length < 1) { return; }
        this.tabEditDataAll = this.tabPlashkaData[0].plashkaData.slice();
        this.editModeChg();
        this.flEditTab = true;
        this.newTabName = this.tabPlashkaData[this.tabIndex].name;
    }
    private closeEditTab() { // закончить редактировать вкладку
        if (!this.tabEnter(false)) {
            return;
        }
        this.flEditTab = false;
        this.removeUncheckPlash();
        this.tabPlashkaData[this.tabIndex].plashkaData = this.tabEditData.slice();
        this.tabEditData = [];
        this.savePlashki();
    }
    private editModeChg() { // смена режима редактирования (все плашки и только для текущей вкладки)
        if (this.flEditShowAll === 'yes') { // переход на все плашки
            let newArr: IPlashkaData[] = [];
            const copytabEditDataAll: IPlashkaData[] = this.tabEditDataAll.slice();
            this.tabEditData = this.cloneArr(this.tabPlashkaData[this.tabIndex].plashkaData.slice());
            this.removeUncheckPlash();
            for (const el of this.tabEditData) {
                for (let i = 0; i < copytabEditDataAll.length; i++) {
                    if (el.widget.id === copytabEditDataAll[i].widget.id) {
                        copytabEditDataAll.splice(i, 1);
                        break;
                    }
                }
            }
            newArr = newArr.concat(this.tabEditData.slice());
            newArr = newArr.concat(copytabEditDataAll.slice());
            this.tabEditData = this.cloneArr(newArr.slice());
        } else { // плашки текущей страницы
            this.removeUncheckPlash();
        }
    }
    private removeUncheckPlash() { // убрать не отмеченые плашки в режиме редактирования
        for (let i = 0; i < this.tabEditData.length; i++) {
            if (this.tabEditData[i].checked !== true) {
                this.tabEditData.splice(i, 1);
                i--;
            }
        }
    }

    private savePlashki() { // сохранение плашек
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const saveArr: any[] = [];
        for (const obj of this.tabPlashkaData) {
            if (obj.name !== 'Все') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const saveObj: any = { indx: obj.indx, name: obj.name, plashkaData: [] };
                for (const plashkaData of obj.plashkaData) {
                    if (plashkaData.checked) {
                        saveObj.plashkaData.push(plashkaData.widget.id);
                    }
                }
                saveArr.push(saveObj);
            }
        }
        fetch('/api/plashki/position', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, **/**',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(saveArr)
        })
            .then(data => data.json())
            // eslint-disable-next-line no-console
            .then(json => console.log(json));
    }

    private async getPlashki() { // получение плашек из сохранения
        const tabPlashkaData1: ITabPlashkaData[] = [];
        if (this.tabPlashkaData.length === 0) { return; }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let savedArr: any[] = [];
        await fetch('/api/plashki/position')
            .then(response => response.json())
            .then(json => (savedArr = json));

        let i = 1;

        for (const obj of savedArr) {
            const data: ITabPlashkaData = { indx: i, name: obj.name, plashkaData: [] };
            const plashkaData = this.tabPlashkaData[0].plashkaData.slice();
            for (const objPlashkaData of obj.plashkaData) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < plashkaData.length; j++) {
                    if (objPlashkaData === plashkaData[j].widget.id) {
                        data.plashkaData.push({ checked: true, widget: plashkaData[j].widget });
                        plashkaData.splice(j, 1);
                        break;
                    }
                }
            }
            tabPlashkaData1.push(data);
            i++;
        }
        this.tabPlashkaData = this.tabPlashkaData.concat(tabPlashkaData1);
    }

    private isTablet = false; // планшетная версия


    private created() {
        this.windowResizeEventHandler();
        // window.addEventListener("resize", this.windowResizeEventHandler);
    }

    private mounted() {
        if (this.$route.path === '/programs') {
            this.tabIndex = 14;
        }
        if (this.$route.path === '/budget') {
            this.tabIndex = 6;
        }
    }


    private windowResizeEventHandler() {
        if (window.innerWidth <= this.maxTabWidth) {
            this.colSum = 2;
            this.isTablet = true;
        }

        if (window.innerWidth <= 500) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    }

    private cloneArr(arr: any[]): any[] {
        const newArr: any[] = [];
        for (const el of arr) {
            newArr.push(Object.assign({}, el));
        }
        return newArr;
    }
}
